<template>
  <div class="ds-wrapper">
    <div class="custom-quillEditor">
      <div v-if="this.$route.params.type === 'PRODUCT_TC'">
        <p class="font-medium text-4xl text-black mb-4">{{ legals.documentName }}</p>
        <QuillEditorPreview v-model="legals.fullDocument" class="text-base terms-view-content"></QuillEditorPreview>
      </div>
      <div v-else-if="this.$route.params.type === 'PRIVACY'">
        <p class="font-medium text-4xl text-black mb-4">{{ privacy.documentName }}</p>
        <QuillEditorPreview v-model="privacy.fullDocument" class="text-base terms-view-content"></QuillEditorPreview>
      </div>
      <div v-else-if="this.$route.params.type === 'MERCHANT-ONBOARDING-T&C'">
        <p class="font-medium text-4xl text-black mb-4">{{ merchantOnboardingTerms.documentName }}</p>
        <QuillEditorPreview v-model="merchantOnboardingTerms.fullDocument" class="text-base terms-view-content"></QuillEditorPreview>
      </div>
      <div v-else-if="this.$route.params.type === 'GENERAL-WEBSITE-TERMS'">
        <p class="font-medium text-4xl text-black mb-4">{{ generalWebsiteTerms.documentName }}</p>
        <QuillEditorPreview v-model="generalWebsiteTerms.fullDocument" class="text-base terms-view-content"></QuillEditorPreview>
      </div>
      <div v-else>
        <p class="font-medium text-4xl text-black mb-4">{{ payNow.documentName }}</p>
        <QuillEditorPreview v-model="payNow.fullDocument" class="text-base terms-view-content"></QuillEditorPreview>
      </div>
      <div class="mobile-footer">
        <the-footer />
      </div>
    </div>
  </div>
</template>

<script>
import TheFooter from "@/layouts/customer-components/TheFooter";
import QuillEditorPreview from "@/views/components/QuillEditorPreview";
import { mapActions } from "vuex";

export default {
  components: {
    TheFooter,
    QuillEditorPreview,
  },
  data() {
    return {
      paymentRequestDetail: {},
      legals: {},
      privacy: {},
      payNow: {},
      merchantOnboardingTerms: {},
      footerBind: false,
      appUrl: process.env.VUE_APP_BASE_URL,
      generalWebsiteTerms:{}
    };
  },
  methods: {
    ...mapActions("onboardMerchant", ["getPayNowTerm"]),
    ...mapActions("plans", ["fetchProductLegalsById"]),
    async getPayNowTerms() {
      this.$vs.loading();
      await this.getPayNowTerm().then((response) => {
        this.$vs.loading.close();
        this.privacy = response.data.data.privacy;
        this.payNow = response.data.data.payNow;
        this.generalWebsiteTerms = response.data.data.generalWebsiteTerms;
        this.merchantOnboardingTerms = response.data.data.merchantOnbaordingTerms;

        this.merchantOnboardingTerms.explicitTerms = this.replaceLegalContent(
          this.merchantOnboardingTerms.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}legals/MERCHANT-ONBOARDING-T&C?id=${this.merchantOnboardingTerms._id}" target="_blank">${this.merchantOnboardingTerms.documentName}</a>`,
            privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.privacy.documentName}</a>`,
          }
        );
        this.merchantOnboardingTerms.fullDocument = this.replaceLegalContent(
          this.merchantOnboardingTerms.fullDocument,
          {
            fullTerms: `<a href="${this.appUrl}legals/MERCHANT-ONBOARDING-T&C?id=${this.merchantOnboardingTerms._id}" target="_blank">${this.merchantOnboardingTerms.documentName}</a>`,
            privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.privacy.documentName}</a>`,
          }
        );

        this.payNow.explicitTerms = this.replaceLegalContent(
          this.payNow.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}legals/PAYNOW_TC?id=${this.payNow._id}" target="_blank">${this.payNow.documentName}</a>`,
            privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.privacy.documentName}</a>`,
          }
        );
        this.payNow.fullDocument = this.replaceLegalContent(
          this.payNow.fullDocument,
          {
            fullTerms: `<a href="${this.appUrl}legals/PAYNOW_TC?id=${this.payNow._id}" target="_blank">${this.payNow.documentName}</a>`,
            privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.privacy.documentName}</a>`,
          }
        );
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });;
    },
    fetchProductLegals() {
      this.$vs.loading();
      this.fetchProductLegalsById(this.$route.query.id).then((result) => {
        this.$vs.loading.close();
        const { legals } = result.data.data.legals;
        this.legals = legals.find((el) => el._id == this.$route.query.id);

        this.legals.explicitTerms = this.replaceLegalContent(
          this.legals.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}legals/PRODUCT_TC?id=${this.legals._id}" target="_blank">${this.legals.documentName}</a>`,
            privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.privacy.documentName}</a>`,
          }
        );
        this.legals.fullDocument = this.replaceLegalContent(
          this.legals.fullDocument,
          {
            fullTerms: `<a href="${this.appUrl}legals/PRODUCT_TC?id=${this.legals._id}" target="_blank">${this.legals.documentName}</a>`,
            privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.privacy.documentName}</a>`,
          }
        );
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
  },
  async created() {
    await this.getPayNowTerms();

    if (this.$route.params.type === "PRODUCT_TC") {
      await this.fetchProductLegals();
    }
  },
};
</script>
